* {
  box-sizing: border-box;
}

html, body, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: #d2dae2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  width: 100%;
  padding: 20px;
  background-color: #1e272e;
  user-select: none;
}

.Logo {
  font-size: 3rem;
  color: white;
  font-weight: 300;
  background: url("../public/cool.svg") no-repeat;
  padding-left: 5rem;
}
.Logo > span {
  font-style: italic;
  font-weight: 600;
}

.Container {
  position: relative;
  width: 600px;
  height: max-content;
  padding: 20px;
  margin: 4rem auto 4rem;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #cccccc;
  background-color: #fff;
}

div.Info {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: max-content;
  grid-column-gap: 25px;
  grid-row-gap: 5px;
  border: 1px solid #1e272e;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 25px;
}
div.Info > span:nth-child(odd) {
  font-weight: 600;
}

form {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 20px;
}
label.Info {
  position: absolute;
  top: 7px;
  background-color: #fff;
  padding: 0 10px;
  margin-left: 10px;
}
label.Text {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}
input[type=text], textarea {
  width: 100%;
  outline: none;
  border: 2px solid #d2dae2;
  box-shadow: 0 0 2px 0 #d2dae2;
  border-radius: 2px;
  height: 2rem;
  padding: 0 10px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 1rem;
}
input[type=text]:focus, textarea:focus {
  border-color: #1e272e;
}
textarea {
  padding: 10px;
  height: 10rem;
  resize: none;
}

.Span_Button {
  display: block;
  width: 100%;
  padding: 15px 0;
  background-color: #1e272e;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
}
.Hallo {
  font-size: 1.5rem;
}
div.Split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
span.AC_Button, button {
  padding: 15px;
  text-align: center;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
button {
  outline: none;
}
.Green {
  background-color: #05c46b;
}
.Red {
  background-color: #ff3f34;
}
a.Spotify {
  color: #1e272e;
  background: url("../public/spotify.svg") no-repeat;
  padding-left: 2rem;
}

img {
  height: 1rem;
}

.CB_Wrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: max-content;
  grid-gap: 5px;
}